import styled, { css } from 'styled-components';
import { Icons } from '../../icons';
import { useState } from 'react';

type Props = {
  isPressed?: boolean;
  onClick: () => void;
  isDisabled?: boolean;
} & React.HTMLAttributes<HTMLButtonElement>;

export const StyledCalculatorButton = styled.button<{
  isPressed?: boolean;
  isDisabled?: boolean;
}>`
  svg {
    width: 28px;
    height: 28px;
    opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
  }
  width: 52px;
  height: 52px;
  padding: 12px;
  display: flex;
  align-items: center;
  z-index: 2;
  justify-content: center;
  cursor: pointer;
  border: none;
  border-radius: ${({ theme }) => theme.borders.radius.md};

  ${({ theme, isPressed, isDisabled }) => {
    if (isDisabled) {
      return css`
        pointer-events: ${isDisabled ? 'none' : 'auto'};
        background-color: rgba(255, 255, 255, 0.5);
        box-shadow: none;
      `;
    }

    return css`
      background-color: ${isPressed
        ? theme.colors.grey['05']
        : theme.colors.white};
      box-shadow: ${!isPressed
        ? `0px 1.4px 1.4px 0px ${theme.colors.grey['08']}`
        : 'none'};

      &:hover {
        background-color: ${isPressed
          ? theme.colors.grey['05']
          : theme.colors.grey['07']};
      }
      &:active {
        box-shadow: none;
      }
    `;
  }}
  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    svg {
      width: 24px;
      height: 24px;
    }
    width: 44px;
    height: 44px;
    padding: 10px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    svg {
      width: 20px;
      height: 20px;
    }
    width: 36px;
    height: 36px;
    padding: 8.3px;
  }
`;

export function CalculatorButton({ isPressed, onClick, isDisabled }: Props) {
  const [isClicked, setIsClicked] = useState(false);
  const CalculatorIcon = Icons['CalculatorIcon'];

  const isDisabledButton = isDisabled || isClicked;

  const handleClick = () => {
    setIsClicked(true);
    onClick();
  };
  return (
    <StyledCalculatorButton
      aria-label="Calculator"
      isPressed={isPressed}
      onClick={handleClick}
      isDisabled={isDisabledButton}
    >
      <CalculatorIcon />
    </StyledCalculatorButton>
  );
}
