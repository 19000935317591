import React from 'react';

export const LoadingDots = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="29"
    height="28"
    viewBox="0 0 29 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <style>
      {`
        @keyframes bounce {
          0%, 80%, 100% {
            transform: scaleY(0.8);
          }
          40% {
            transform: scaleY(1.2);
          }
        }

        .dot {
          animation: bounce 0.8s infinite ease-in-out;
          transform-origin: center;
        }

        .dot:nth-child(1) {
          animation-delay: 0s;
        }

        .dot:nth-child(2) {
          animation-delay: 0.2s;
        }

        .dot:nth-child(3) {
          animation-delay: 0.4s;
        }
      `}
    </style>
    <g id="Loading_InfiniteLoop">
      <rect
        id="C1"
        x="4.5"
        y="12"
        width="4"
        height="4"
        rx="2"
        fill="#333333"
        className="dot"
      />
      <rect
        id="C2"
        x="12.5"
        y="12"
        width="4"
        height="4"
        rx="2"
        fill="#333333"
        className="dot"
      />
      <rect
        id="C3"
        x="20.5"
        y="12"
        width="4"
        height="4"
        rx="2"
        fill="#333333"
        className="dot"
      />
    </g>
  </svg>
);
