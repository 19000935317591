import styled, { css, keyframes } from 'styled-components';
import { Icons } from '../../icons';

type Props = {
  onSwitchModeClick: () => void;
  isPressed: boolean;
  isDecimalNumber: boolean;
} & React.HTMLAttributes<HTMLButtonElement>;

const rotateAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
`;

const flipAnimation = keyframes`
  0% {
    transform: rotateY(180deg);
  }
  50% {
    transform: rotateY(90deg);
    opacity: 0;
  }
  100% {
    transform: rotateY(0deg);
    opacity: 1;
  }
`;

const DualNumberArrowsSVG = Icons['DualNumberArrowsIcon'];
const DualNumberSVG = Icons['DualNumberIcon'];
const EmptyDualNumberSVG = Icons['EmptyDualNumberIcon'];

const StyledDualModeButton = styled.button<{ isPressed?: boolean }>`
  ${({ theme, isPressed }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: ${theme.borders.radius.md};
    background-color: ${isPressed
      ? theme.colors.grey['05']
      : theme.colors.white};
    box-shadow: ${!isPressed
      ? `0px 1.4px 1.4px 0px ${theme.colors.grey['08']}`
      : 'none'};
    cursor: pointer;
    position: relative;

    &:hover ${EmptyDualNumberIcon} path {
      fill: ${isPressed ? theme.colors.grey['05'] : theme.colors.grey['07']};
    }
    &:hover ${DualNumberIcon} {
      path:first-child {
        fill: ${isPressed ? theme.colors.grey['05'] : theme.colors.grey['07']};
      }
    }

    &:hover {
      background-color: ${isPressed
        ? theme.colors.grey['05']
        : theme.colors.grey['07']};
    }
    &:active {
      box-shadow: none;
    }
  `}
`;

const IconWrapper = styled.div<{ isAnimating?: boolean }>`
  animation: ${({ isAnimating }) => (isAnimating ? flipAnimation : 'none')} 0.6s
    forwards;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  perspective: 50px;
  position: absolute;
`;

const DualNumberIcon = styled(DualNumberSVG)`
  position: absolute;
  transform-origin: center;
  width: 28px;
  height: 19px;
  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    width: 26px;
    height: 17px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 21px;
    height: 14px;
  }
`;

const EmptyDualNumberIcon = styled(EmptyDualNumberSVG)`
  position: absolute;
  transform-origin: center;
  width: 28px;
  height: 19px;
  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    width: 26px;
    height: 17px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 21px;
    height: 14px;
  }
`;

const DualNumberArrowsIcon = styled(DualNumberArrowsSVG)<{ animate?: boolean }>`
  animation: ${({ animate }) => (animate ? rotateAnimation : 'none')} 0.5s ease;
  position: relative;
  width: 52px;
  height: 52px;
  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    width: 44px;
    height: 44px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 36px;
    height: 36px;
  }
`;

export function DualModeButton({
  isPressed = false,
  onSwitchModeClick,
  isDecimalNumber,
}: Props) {
  return (
    <StyledDualModeButton
      role="button"
      aria-label="Dual Number"
      aria-live="polite"
      onClick={() => onSwitchModeClick()}
      isPressed={isPressed}
      data-testid="dual-mode-button"
    >
      <IconWrapper isAnimating={isPressed} data-testid="icon-wrapper">
        {isDecimalNumber ? (
          <EmptyDualNumberIcon data-testid="empty-dual-number-icon" />
        ) : (
          <DualNumberIcon data-testid="dual-number-icon" />
        )}
      </IconWrapper>
      <DualNumberArrowsIcon
        animate={isPressed}
        data-testid="dual-number-arrows-icon"
      />
    </StyledDualModeButton>
  );
}
