import { t } from 'i18next';
import styled from 'styled-components';
import { DualModeButton } from '../dual-mode-button';
import { ResultInput } from '../result-input';
import { Tooltip } from '../tooltip';

type CalculatorResultProps = {
  onResultClick: () => void;
  onSwitchModeClick: () => void;
  resultContent: string;
  isLoading?: boolean;
  isDecimalNumber: boolean;
  isAnimatingDualButton: boolean;
  isError?: boolean;
  hasCalculatorSolution: boolean;
  operationContent: string;
};

const ResultWrapper = styled.div<{ hasCalculatorSolution: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: ${({ hasCalculatorSolution, theme }) =>
    hasCalculatorSolution ? 'rgba(255, 255, 255, 0.50)' : theme.colors.white};
  gap: 12px;
  border-radius: 9.6px;
  padding: 4px 12px;
  transition: background-color 0.5s;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    gap: 10px;
    padding: 4px 10px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    gap: 8px;
    padding: 2px 8px;
  }
`;

export function CalculatorResult({
  onResultClick,
  resultContent,
  isLoading,
  onSwitchModeClick,
  isDecimalNumber,
  isAnimatingDualButton,
  isError,
  hasCalculatorSolution,
  operationContent,
}: CalculatorResultProps) {
  return (
    <ResultWrapper hasCalculatorSolution={hasCalculatorSolution}>
      <Tooltip
        content={t('calculator.copiedToClipboard')}
        marginOffset={2}
        trigger="click"
        disableTooltip={isError}
        triggerComponent={
          <ResultInput
            onClick={onResultClick}
            content={resultContent}
            data-testid="result-input"
            isLoading={isLoading}
            isError={isError}
            hasCalculatorSolution={hasCalculatorSolution}
            operationContent={operationContent}
          />
        }
      />
      <DualModeButton
        data-testid="dual-mode-button"
        onSwitchModeClick={onSwitchModeClick}
        isDecimalNumber={isDecimalNumber}
        isPressed={isAnimatingDualButton}
      />
    </ResultWrapper>
  );
}
