import styled, { css } from 'styled-components';

export const Container = styled.button<{
  isLoading?: boolean;
  isDisabled?: boolean;
}>`
  min-width: 120px;
  min-height: 116px;
  border: none;
  border-radius: ${({ theme }) => theme.borders.radius.md};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24);
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ isDisabled, isLoading }) => {
    if (isDisabled) {
      return css`
        background-color: ${({ theme }) => theme.colors.brand['02']};
        color: ${({ theme }) => theme.colors.grey['04']};
        cursor: default;
        box-shadow: none;
      `;
    }
    if (isLoading) {
      return css`
        background-color: ${({ theme }) => theme.colors.brand.dark['02']};
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24);
        cursor: default;
      `;
    }
    return css`
      background-color: ${({ theme }) => theme.colors.brand['01']};
      cursor: pointer;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24);
      :hover {
        background-color: ${({ theme }) => theme.colors.brand.dark['02']};
      }
      :active {
        background-color: ${({ theme }) => theme.colors.brand.dark['02']};
        box-shadow: 0px 0px 0px;
      }
    `;
  }}

  svg {
    path {
      fill: ${({ theme }) => theme.colors.white};
      opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    min-width: ${({ theme }) => theme.sizes.xxbig};
    min-height: 95px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-width: ${({ theme }) => theme.sizes.xbig};
    min-height: 75px;
  }
`;
