import { t } from 'i18next';
import styled, { css } from 'styled-components';
import { Icons } from '../../icons';
import { isMathMLEmpty } from '../../utils/is-mathml-empty';
import { CalculatorOperation } from '../calculator-operation';
import { CalculatorResult } from '../calculator-result';

type Props = {
  hasCalculatorSolution: boolean;
  isAnimatingDualButton: boolean;
  isDecimalNumber: boolean;
  isError?: boolean;
  isLoading?: boolean;
  operationContent: string;
  resultContent: string;
  onResultClick: () => void;
  onSwitchModeClick: () => void;
};

const CalculatorWrapper = styled.div`
  width: 460px;
  height: 80px;
  background-color: 'transparent';
  border-radius: 12px;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: space-between;
  padding: 4px 4px 4px 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    width: 423px;
    height: 74px;
    padding: 4px 4px 4px 14px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 360px;
    height: ${({ theme }) => theme.sizes.xxlarge};
    padding: 2px 2px 2px 12px;
  }
`;

const CalculatorInputContainer = styled.div<{ hasCalculatorSolution: boolean }>`
  width: 460px;
  height: 80px;
  border-radius: 12px;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: flex-start;
  padding-left: 16px;
  left: 0;
  transform-origin: right center;

  ${({ hasCalculatorSolution }) => css`
    background-color: ${hasCalculatorSolution
      ? 'transparent'
      : 'rgba(255, 255, 255, 0.5)'};
    transform: scaleX(${hasCalculatorSolution ? 0 : 1});
    visibility: ${hasCalculatorSolution ? 'hidden' : 'visible'};
  `}

  transition:
    transform 0.5s ease-in-out,
    background-color 0.5s ease-in-out,
    visibility 0.5s ease-in-out;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    width: 423px;
    height: 74px;
    padding-right: 14px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 360px;
    height: ${({ theme }) => theme.sizes.xxlarge};
    padding-right: 12px;
  }
`;

const OperationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
`;

const OperationContainer = styled.div<{ isVisible: boolean }>`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  z-index: ${({ isVisible }) => (isVisible ? 2 : 1)};

  transition: opacity 0.3s ease-in-out;
`;

const PlaceholderCalculator = styled.div<{ isVisible: boolean }>`
  color: ${({ theme }) => theme.colors.grey['02']};
  text-align: center;
  font-family: ${({ theme }) => theme.typography.regular};
  font-size: ${({ theme }) => theme.sizes.semicaption};
  line-height: 30px;
  font-style: normal;
  font-weight: 400;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  z-index: ${({ isVisible }) => (isVisible ? 2 : 1)};

  transition: opacity 0.3s ease-in-out;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    font-size: ${({ theme }) => theme.sizes.xmedium};
    line-height: 26px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.sizes.normal};
    line-height: 22px;
  }
`;

const IconWrapper = styled.div`
  width: 32px;
  height: 32px;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    width: 30px;
    height: 30px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 24px;
    height: 24px;
  }
`;

const CalculatorIcon = Icons['CalculatorDarkIcon'];
const CalculatorSvg = styled(CalculatorIcon)<{ isVisible: boolean }>`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  width: 32px;
  height: 32px;

  transition: opacity 0.2s;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    width: 30px;
    height: 30px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 24px;
    height: 24px;
  }
`;

export function CalculatorInput({
  hasCalculatorSolution,
  isAnimatingDualButton,
  isDecimalNumber,
  isError,
  isLoading,
  operationContent,
  resultContent,
  onResultClick,
  onSwitchModeClick,
}: Props) {
  const hasContent: boolean =
    Boolean(operationContent) && !isMathMLEmpty(operationContent);

  return (
    <CalculatorWrapper>
      <CalculatorInputContainer hasCalculatorSolution={hasCalculatorSolution}>
        <IconWrapper>
          <CalculatorSvg
            isVisible={!hasCalculatorSolution}
            data-testid="calculator-svg"
          />
        </IconWrapper>
      </CalculatorInputContainer>
      <OperationWrapper>
        <OperationContainer isVisible={hasContent}>
          <CalculatorOperation operationContent={operationContent} />
          <CalculatorResult
            hasCalculatorSolution={hasCalculatorSolution}
            isAnimatingDualButton={isAnimatingDualButton}
            isDecimalNumber={isDecimalNumber}
            isError={isError}
            isLoading={isLoading}
            operationContent={operationContent}
            resultContent={resultContent}
            onResultClick={onResultClick}
            onSwitchModeClick={onSwitchModeClick}
          />
        </OperationContainer>
        <PlaceholderCalculator isVisible={!hasContent}>
          {t('calculator.initialPlaceholder')}
        </PlaceholderCalculator>
      </OperationWrapper>
    </CalculatorWrapper>
  );
}
