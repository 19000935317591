import { createContext, useContext, useEffect, useRef, useState } from 'react';

import {
  ActivityState,
  Feedback,
  LAYOUTS,
  Metrics,
  Payload,
  Statement,
} from '../utils/types';
import { defaultActivityState, defaultPayload } from '../utils/default-values';
import { generateMetric } from '../utils/generate-metrics';
import {
  getElapsedTime,
  getTotalTime,
  initTimer,
  removeTimer,
} from '../utils/timer';

import useGetActivities from '../hooks/use-get-activities';
import { getStatement, useGetStatement } from '../hooks/use-get-statement';
import useValidateWirisAnswers from '../hooks/use-validate-wiris-answers';

import { useMessageContext } from './message-provider';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { setAxiosInterceptors, useNetworkContext } from '@web-contents/shared';

const ActivityStateContext = createContext<Payload & ActivityState>({
  ...defaultPayload,
  ...defaultActivityState,
});

interface ActivityProviderProps {
  payload: Payload;
  children?: React.ReactNode;
  apiUrl: string;
}

function ActivityProvider({
  payload,
  children,
  apiUrl,
}: ActivityProviderProps) {
  const { setSlowRequest } = useNetworkContext();
  setAxiosInterceptors(setSlowRequest);

  const initialActivityStateValues = {
    ...payload,
    ...defaultActivityState,
  };

  const [activityState, setActivityState] = useState<Payload & ActivityState>(
    initialActivityStateValues,
  );

  const [isActivityCompleted, setIsActivityCompleted] =
    useState<boolean>(false);

  const [buildLogUrl, setBuildLogUrl] = useState<string | undefined>();

  useEffect(() => {
    if (!payload.devParameters) {
      setBuildLogUrl(undefined);
    } else {
      setBuildLogUrl(payload.devParameters.buildLogUrl);
    }
  }, [payload.devParameters]);

  const currentStatementIndex = useRef<number>(0);
  const metrics = useRef<Metrics>({
    correct: 0,
    incorrect: 0,
  });

  const {
    activityParameters: { id, type, minDuration, maxDuration, minStatements },
  } = payload;

  // TODO: Handle errors from BE or give feedback to the user
  const { data: activity } = useGetActivities({
    id,
    type,
    apiUrl,
    minStatements,
  });

  const { data: firstStatement, status: firstStatementLoadingStatus } =
    useGetStatement({
      activityId: activity?.id,
      statementId: activity?.statements[0],
      type,
      apiUrl,
    });

  const { updateMessageOutput } = useMessageContext();
  const { mutate: validateWirisAnswers } = useValidateWirisAnswers();

  const completeActivity = (): void => {
    removeTimer();
    setIsActivityCompleted(true);
  };

  const selectNextStatement = async ({
    totalStatements,
  }: {
    totalStatements: number;
  }): Promise<void> => {
    const duration = getElapsedTime();
    const isMinTimeExceeded = duration > minDuration;
    const isMaxTimeExceeded = duration >= maxDuration;
    const isMinStatementsAchieved = metrics.current.correct >= minStatements;

    if (
      isMaxTimeExceeded ||
      (isMinTimeExceeded && isMinStatementsAchieved) ||
      currentStatementIndex.current >= totalStatements - 1
    ) {
      completeActivity();
      return;
    }

    const newStatementIndex = currentStatementIndex.current + 1;
    currentStatementIndex.current = newStatementIndex;

    const nextStatementId = activity.statements[newStatementIndex];

    const currentStatement: Statement = await getStatement(
      nextStatementId,
      activity.id,
      apiUrl,
    );

    setActivityState((prevState) => ({
      ...prevState,
      currentStatement,
      totalStatements,
      actions: {
        resolveStatement,
        selectNextStatement,
      },
    }));
  };

  const resolveStatement = async (
    answer: string[] | string[][],
    currentStatement: Statement,
  ): Promise<Feedback> => {
    const result = await new Promise<Feedback>((resolve) => {
      validateWirisAnswers(
        {
          body: {
            questionId: currentStatement!.id,
            seed: currentStatement!.seed,
            studentAnswers: answer,
          },
          apiUrl,
        },
        {
          onSuccess: (data: any) => {
            resolve(data);
          },
        },
      );
    });

    // Only for layout 3 (Interactive Table)
    if (currentStatement.layout === LAYOUTS.INTERACTIVE_TABLE) {
      const totalSlots = result.slotsCorrectness.length;
      const correctSlots = result.slotsCorrectness.filter(
        (slot) => slot.correct === true,
      ).length;
      const incorrectSlots = totalSlots - correctSlots;

      metrics.current = {
        ...metrics.current,
        correct: metrics.current.correct + correctSlots / totalSlots,
        incorrect: metrics.current.incorrect + incorrectSlots / totalSlots,
      };
    } else {
      metrics.current = {
        ...metrics.current,
        correct: metrics.current.correct + (result.isCorrect ? 1 : 0),
        incorrect: metrics.current.incorrect + (result.isCorrect ? 0 : 1),
      };
    }

    return result;
  };

  useEffect(() => {
    setActivityState((preview) => {
      return { ...preview, ...initialActivityStateValues };
    });
  }, [buildLogUrl]);

  useEffect(() => {
    if (isActivityCompleted) {
      updateMessageOutput({
        eventId: 'ActivityIsCompleted',
        arguments: {
          activityId: id,
          metrics: generateMetric(metrics.current),
          totalTime: getTotalTime(),
        },
      });
    }
  }, [isActivityCompleted]);

  useEffect(() => {
    if (firstStatementLoadingStatus === 'success') {
      initTimer();

      setActivityState((prevActivityState) => ({
        ...prevActivityState,
        isCalculatorAvailable: activity.calculator_available,
        title: activity.title,
        statements: activity.statements,
        totalStatements: activity.statements.length,
        currentStatement: firstStatement,
        actions: {
          resolveStatement,
          selectNextStatement,
        },
      }));
    }
  }, [firstStatementLoadingStatus]);

  return (
    <ActivityStateContext.Provider value={activityState}>
      {children}
    </ActivityStateContext.Provider>
  );
}

function useActivityContext() {
  const context = useContext(ActivityStateContext);
  if (context === undefined) {
    throw new Error('useActivity must be used within a ActivityProvider');
  }
  return context;
}

export { ActivityProvider, useActivityContext };
