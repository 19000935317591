import { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Icons } from '../../icons';

const StyledDisplayButton = styled.button<{
  isPressed?: boolean;
  isDisabled?: boolean;
}>`
  svg {
    width: 28px;
    height: 28px;
    opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
  }
  width: 52px;
  height: 52px;
  padding: 12px;
  display: flex;
  align-items: center;
  z-index: 2;
  justify-content: center;
  cursor: pointer;
  border: none;
  border-radius: ${({ theme }) => theme.borders.radius.md};

  ${({ theme, isPressed, isDisabled }) => {
    if (isDisabled) {
      return css`
        pointer-events: ${isDisabled ? 'none' : 'auto'};
        background-color: rgba(255, 255, 255, 0.5);
        box-shadow: none;
      `;
    }

    return css`
      background-color: ${isPressed
        ? theme.colors.grey['05']
        : theme.colors.white};
      box-shadow: ${!isPressed
        ? `0px 1.4px 1.4px 0px ${theme.colors.grey['08']}`
        : 'none'};

      &:hover {
        background-color: ${isPressed
          ? theme.colors.grey['05']
          : theme.colors.grey['07']};
      }
      &:active {
        box-shadow: none;
      }
    `;
  }}
  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    svg {
      width: 24px;
      height: 24px;
    }
    width: 44px;
    height: 44px;
    padding: 10px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    svg {
      width: 20px;
      height: 20px;
    }
    width: 36px;
    height: 36px;
    padding: 8.3px;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
`;

const flipIn = () => keyframes`
  from {
    transform: rotateY(-90deg);
    opacity: 0;
  }
  to {
    transform: rotateY(0deg);
    opacity: 1;
  }
`;

const flipOut = () => keyframes`
  from {
    transform: rotateY(90deg);
    opacity: 0;
  }
  to {
    transform: rotateY(0deg);
    opacity: 1;
  }
`;

const IconFront = styled.div<{ isPressed: boolean; prevIsPressed: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${({ isPressed: pressed, prevIsPressed }) => {
    if (pressed !== prevIsPressed) {
      return css`
        ${pressed
          ? flipOut()
          : flipIn()} 0.45s cubic-bezier(0.25, 0.1, 0.25, 1.0)
      `;
    }
  }};
  backface-visibility: hidden;
`;

export type DisplayButtonIcon = 'CalculatorIcon' | 'CloseIcon';

type DisplayButtonProps = {
  icon: DisplayButtonIcon;
  isDisabled?: boolean;
  isPressed?: boolean;
  onClick: () => void;
};

let prevIsPressed = true;

export function DisplayButton({
  icon,
  isDisabled,
  isPressed,
  onClick,
}: DisplayButtonProps) {
  const [pressed, setPressed] = useState(isPressed);
  const Icon = Icons[icon];
  const isCalculatorButtonDisabled = icon === 'CalculatorIcon' && isDisabled;

  const handleClick = () => {
    setPressed(!pressed);
    onClick();
  };

  useEffect(() => {
    if (pressed !== prevIsPressed) {
      prevIsPressed = isPressed ?? false;
    }
    setPressed(isPressed);
  }, [pressed, isPressed]);

  return (
    <StyledDisplayButton
      onClick={handleClick}
      isDisabled={isCalculatorButtonDisabled}
    >
      <IconWrapper>
        <IconFront isPressed={pressed ?? false} prevIsPressed={prevIsPressed}>
          <Icon />
        </IconFront>
      </IconWrapper>
    </StyledDisplayButton>
  );
}
