import { useEventListener } from '@web-contents/shared';
import styled, { css } from 'styled-components';

type Props = {
  onClick: () => void;
  isPressed?: boolean;
} & React.HTMLAttributes<HTMLButtonElement>;

const StyledResultButton = styled.button<{ isPressed?: boolean }>`
  ${({ theme, isPressed }) => {
    return css`
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 120px;
      min-height: 116px;
      color: ${theme.colors.grey['01']};
      border-radius: ${theme.borders.radius.md};
      background-color: ${isPressed
        ? `linear-gradient(0deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%), ${theme.colors.grey['07']}`
        : theme.colors.grey['06']};
      box-shadow: ${!isPressed
        ? `0px 2.587px 2.587px 0px ${theme.colors.grey['08']}`
        : 'none'};
      cursor: pointer;
      :hover {
        background: ${isPressed
          ? theme.colors.grey['05']
          : `linear-gradient(0deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.08) 100%), ${theme.colors.grey['07']}`};
      }
      &:active {
        box-shadow: none;
      }
      border: none;
    `;
  }}
  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    min-width: ${({ theme }) => theme.sizes.xxbig};
    min-height: 95px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-width: ${({ theme }) => theme.sizes.xbig};
    min-height: 75px;
  }
`;

const StyledEqual = styled.div`
  color: ${({ theme }) => theme.colors.grey['02']};
  font-family: Arial;
  font-size: ${({ theme }) => theme.sizes.big};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    font-size: ${({ theme }) => theme.sizes.xxlarge};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.sizes.xlarge};
  }
`;

export function ResultButton({ onClick, isPressed = false }: Props) {
  useEventListener('keydown', (event) => {
    const keyboardEvent = event as KeyboardEvent;
    if (keyboardEvent.key === 'Enter') {
      onClick?.();
    }
  });

  return (
    <StyledResultButton
      aria-label="Result button"
      role="button"
      onClick={onClick}
      isPressed={isPressed}
    >
      <StyledEqual>=</StyledEqual>
    </StyledResultButton>
  );
}
