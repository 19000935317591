import { MathComponent } from 'mathjax-react';
import { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

type CalculatorOperationProps = {
  operationContent: string;
};

const OperationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    gap: 10px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    gap: 10px;
  }
`;

const Equal = styled.div`
  font-family: ${({ theme }) => theme.typography.regular};
  font-size: ${({ theme }) => theme.sizes.semilarge};
  font-style: normal;
  font-size: 32px;
  font-weight: 400;
  line-height: 56px;
  color: ${({ theme }) => theme.colors.grey['02']};
  padding-right: 12px;
  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    padding-right: 11px;
    font-size: 30px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding-right: 10px;
    font-size: ${({ theme }) => theme.sizes.caption};
  }
`;

const MathComponentWrapper = styled.div`
  width: 120px;
  min-width: 120px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;

  .MathJax {
    text-align: center;
    font-family: ${({ theme }) => theme.typography.cmr10};
    font-size: ${({ theme }) => theme.sizes.caption};
    font-style: normal;
    font-weight: 400;
    line-height: 56px;
    color: ${({ theme }) => theme.colors.grey['03']};
    @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
      font-size: ${({ theme }) => theme.sizes.xmedium};
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    width: 124px;
    height: 66px;
    min-width: 94px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 106px;
    height: 56px;
    min-width: 94px;
  }
`;

const TextGradientWrapper = styled.div<{ isOverflowing: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;

  ${({ isOverflowing }) =>
    isOverflowing &&
    css`
      mask-image: linear-gradient(
        90deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 1) 70%,
        rgba(0, 0, 0, 0) 100%
      );
      -webkit-mask-image: linear-gradient(
        90deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 1) 70%,
        rgba(0, 0, 0, 0) 100%
      );
    `}
`;

export function CalculatorOperation({
  operationContent,
}: CalculatorOperationProps) {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const checkOverflow = () => {
    if (contentRef.current) {
      setIsOverflowing(
        contentRef.current.scrollWidth > contentRef.current.clientWidth,
      );
    }
  };

  useEffect(() => {
    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    const observer = new MutationObserver(checkOverflow);
    if (contentRef.current) {
      observer.observe(contentRef.current, { childList: true, subtree: true });
    }

    return () => {
      window.removeEventListener('resize', checkOverflow);
      observer.disconnect();
    };
  }, [operationContent]);

  if (!operationContent) return null;

  return (
    <OperationWrapper>
      <MathComponentWrapper>
        <TextGradientWrapper
          data-testid="math-component"
          ref={contentRef}
          isOverflowing={isOverflowing}
        >
          <MathComponent mathml={operationContent} />
        </TextGradientWrapper>
      </MathComponentWrapper>
      <Equal>=</Equal>
    </OperationWrapper>
  );
}
