// TODO: Only for ActivityProvider. Add a actionCode to make it scalable
// more info here https://bitbucket.org/innovamatdevteam/web-contents/pull-requests/46/reve-2335-fix-total-activity-time#comment-431921439
export const time = {
  start: 0,
  totalElapsed: 0,
  currentSessionElapsed: 0,
};

let handleVisibilityChange: (() => void) | null;

const startTimer = () => {
  time.start = Date.now();
  time.totalElapsed = 0;
  time.currentSessionElapsed = 0;
};

const stopTimer = () => {
  if (time.start) {
    time.totalElapsed += Date.now() - time.start;
    time.start = 0;
    time.currentSessionElapsed = 0;
  }
};

const pauseTimer = () => {
  if (time.start) {
    time.currentSessionElapsed = Date.now() - time.start;
    time.totalElapsed += time.currentSessionElapsed;
    time.start = 0;
    time.currentSessionElapsed = 0;
  }
};

const unPauseTimer = () => {
  if (!time.start) {
    time.start = Date.now();
  }
};

export const getElapsedTime = () => {
  if (time.start) {
    return Math.round((Date.now() - time.start + time.totalElapsed) / 1000);
  }
  return Math.round(time.totalElapsed / 1000);
};

export const getTotalTime = () => {
  return Math.round(time.totalElapsed / 1000);
};

export const initTimer = () => {
  if (handleVisibilityChange) {
    return;
  }

  startTimer();

  handleVisibilityChange = () => {
    if (document.hidden) {
      pauseTimer();
    } else {
      unPauseTimer();
    }
  };

  document.addEventListener('visibilitychange', handleVisibilityChange);
};

export const removeTimer = () => {
  stopTimer();

  if (handleVisibilityChange) {
    document.removeEventListener('visibilitychange', handleVisibilityChange);
    handleVisibilityChange = null;
  }
};
